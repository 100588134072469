import React from 'react'
import { IconsProps } from 'msp-integrations'

const AIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      width={props?.width || '78'}
      height={props?.height || '83'}
      viewBox="0 0 78 83" xmlns="http://www.w3.org/2000/svg"><path d="m30.218 17.272-.825-2.117L35.382.601h2.214l28.726 70.204c4.017 8.207 8.276 9.112 11.599 9.419v2.199H46.895v-2.199c9.339 0 3.36-13.839 3.36-13.839-1.677-4.168-20.037-49.113-20.037-49.113M16.602 79.395c3.6-3.614 3.6-9.422-.01-12.979-3.61-3.557-9.455-3.512-13.061.099C1.12 68.928.15 72.04.02 75.507c-.113 2.997.276 6.169.813 6.699.492.482 3.576.826 6.552.792 3.433-.039 7.233-1.612 9.217-3.603" />
    </svg>
  )
}

export default AIcon
